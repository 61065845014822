import { FirebaseApp, initializeApp } from "firebase/app"
import * as React from "react"
import Firebase from "../../services/firebase"
import config from "../../utils/config"

let firebase: Firebase

const getFirebase = (app: FirebaseApp) =>
  firebase ?? (firebase = new Firebase(app))

export const FirebaseContext = React.createContext<Firebase | null>(null)

type FirebaseContextProviderProps = {
  children: React.ReactNode
}

export const FirebaseContextProvider = ({
  children,
}: FirebaseContextProviderProps) => {
  const firebase = getFirebase(initializeApp(config.firebaseConfig))
  return (
    <FirebaseContext.Provider value={firebase}>
      {children}
    </FirebaseContext.Provider>
  )
}
